import { Resource } from 'i18next';

import { SUPPORTED_LANGS } from './src/constants';

export function initSSRTranslations(
  localePath: string | ((locale: string, namespace: string, missing: boolean) => string),
  namespaces?: string[],
  resources?: Resource,
) {
  return {
    i18n: {
      defaultLocale: 'en',
      locales: SUPPORTED_LANGS,
    },
    debug: process.env.DEBUG === 'true',
    react: {
      useSuspense: false,
      defaultTransParent: 'span', // IMPORTANT: not having a parent for the <Trans /> component crashes the app for Google Translate users
    },
    localePath,
    resources,
    ns: namespaces,
    serializeConfig: false,
  };
}

export * from './src/ssr';
