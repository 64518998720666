import {
  // eslint-disable-next-line no-restricted-imports
  getServerSideTranslations as _getServerSideTranslations,
  initSSRTranslations,
  SSRConfig,
} from '@sortlist-frontend/translation/ssr';

import { getResourcePath, namespaces } from './server-only';

export const translationsConfig = initSSRTranslations(getResourcePath, [...namespaces, 'base']);

export const getServerSideTranslations = async (
  locale: string,
  namespacesRequired?: string[] | Readonly<string[]> | undefined,
): Promise<SSRConfig> => {
  // Slice needed here cause serverSlideTranslations does not accept Readonly type
  return _getServerSideTranslations(locale, [...(namespacesRequired?.slice() ?? []), 'base'], translationsConfig);
};
