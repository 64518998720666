import '@sortlist-frontend/design-system/styles.css';
import '_assets/theme/style.scss';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { withSSRTranslations } from '@sortlist-frontend/translation/ssr';
import type { AppProps as NextAppProps } from 'next/app';
import { Inter, Playfair_Display } from 'next/font/google';

import { createEmotionCache } from '_core/nextjs/create-emotion-cache';
import { translationsConfig } from '_core/utils/ssr';

const inter = Inter({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
});

const playfair = Playfair_Display({
  weight: ['400', '700'],
  subsets: ['latin'],
  variable: '--font-serif',
  display: 'swap',
});

// Workaround for https://github.com/zeit/next.js/issues/8592
export type AppProps = NextAppProps & {
  /** Will be defined only is there was an error */
  err?: Error;
  /** Explicit emotion cache required for mui v5 */
  emotionCache?: EmotionCache;
};

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
const MyApp = (appProps: AppProps) => {
  const { Component, pageProps, emotionCache = clientSideEmotionCache, err } = appProps;

  return (
    <CacheProvider value={emotionCache}>
      <main id="__main" className={`${inter.className} ${playfair.variable}`}>
        <Component {...pageProps} err={err} />
      </main>
    </CacheProvider>
  );
};

export default withSSRTranslations(MyApp, translationsConfig);
