import fs from 'fs';
import path from 'path';
import { getTranslationInstance } from '@sortlist-frontend/translation/server-only';

const LOCALES_DIRECTORY = path.join(process.cwd(), 'public/static/locales/en');

let namespaces: string[] = [];

if (typeof window === 'undefined') {
  const res = fs.readdirSync(LOCALES_DIRECTORY);
  namespaces = res.map((file) => file.split('.')[0]);
}

export { namespaces };

// Big hack to resolve the full path so that on the server we can find the file correctly
// Tried with require.resolve but webpack takes over at build time and returns the module
// id (number) instead of the path
const sharedComponentsFullPath = path.resolve(process.cwd(), '../../packages/shared-components');

export function getResourcePath(locale: string, namespace: string) {
  if (namespace === 'base') {
    return path.resolve(sharedComponentsFullPath, 'locales', locale, 'base.json');
  }

  return typeof window === 'undefined'
    ? path.resolve('./public/static/locales', locale, `${namespace}.json`)
    : `/public/static/locales/${locale}/${namespace}.json`;
}

export const useTranslations = async (
  locale: string,
  namespacesRequired?: string[] | Readonly<string[]> | undefined,
) => {
  return getTranslationInstance(locale, [...(namespacesRequired?.slice() ?? []), 'base'], 'public/static/locales');
};
